import SecureView from "../../../shared/common/SecureView";
import IModule from "../../../core/interfaces/IModule";
import EModules from "../../../core/enums/EModules";
import ECommonPermissions from "../../../core/enums/ECommonPermissions";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../../core/store/store";
import {useEffect, useState} from "react";
import Card from "../../../shared/lib/Card";
import CardBody from "../../../shared/lib/CardBody";
import {Button, Col, Form, InputGroup, Row} from "react-bootstrap";
import loadModuleInformation from "../../../core/utils/loadModuleInformation";
import ICompanyModule from "../../../core/interfaces/ICompanyModule";
import CapitalizeAndTransform from "../../../core/utils/CapitalizeAndTransform";
import findCompanyDepartmentsRequest
  from "../../../core/api/requests/company/company-departments/findCompanyDepartmentsRequest";
import {setDepartments, setProducts} from "../../../core/store/slices/companyCommons";
import ISubdepartment from "../../../core/interfaces/ISubdepartment";
import IDepartment from "../../../core/interfaces/IDepartment";
import findCompanySubdepartmentsRequest
  from "../../../core/api/requests/company/company-departments/subdepartments/findCompanySubdepartmentsRequest";
import findCompanyProductsRequest from "../../../core/api/requests/company/company-products/findCompanyProductsRequest";
import IProduct from "../../../core/interfaces/IProduct";
import RCAlerts from "../../../core/utils/swal/RCAlerts";
import {useNavigate} from "react-router-dom";
import ERoutes from "../../../core/enums/ERoutes";
import transformToSingular from "../../../core/utils/transformToSingular";
import createCompanyProductStockRequest
  from "../../../core/api/requests/company/company-stock/createCompanyProductStockRequest";
import EHttpStatusCode from "../../../core/enums/EHttpStatusCodes";
import {setScanner} from "../../../core/store/slices/scannerSlice";
import WeightScale from "../../../shared/common/WeightScale";
import {Autocomplete, TextField} from "@mui/material";

const m: IModule = {
  module: EModules.COMPANY_STOCK,
  method: ECommonPermissions.CREATE,
  name: 'Stock',
  singularName: 'Stock',
}

export default function () {
  const selectedCompany = useSelector((state: RootState) => state.selectedCompany)
  const permissions = useSelector((state: RootState) => state.permissions)
  const companyCommons = useSelector((state: RootState) => state.companyCommons)
  const dispatch = useDispatch();
  const [ module, setModule ] = useState<IModule>(m)

  const [ departmentModule, setDepartmentModule ] = useState<ICompanyModule>()

  const [ subdepartments, setSubdepartments ] = useState<ISubdepartment[]>([])

  const [ department, setDepartment ] = useState<IDepartment>()
  const [ subdepartment, setSubdepartment ] = useState<ISubdepartment>()

  const [ product, setProduct ] = useState<IProduct>()

  const [ amount, setAmount ] = useState<string>()

  const [ description, setDescription ] = useState<string>('')

  const navigate = useNavigate();

  const loadModules = () => {
    const department = loadModuleInformation(selectedCompany?.company, EModules.COMPANY_DEPARTMENTS)
    if (department) {
      setDepartmentModule(department)
    }
  }

  const goBack = () => {
    if (!selectedCompany.company) return
    navigate(ERoutes.COMPANY_STOCK_VIEW.replaceAll(":cid", selectedCompany.company.id))
  }

  const getDepartments = async () => {
    if (!selectedCompany.company) return;

    const response = await findCompanyDepartmentsRequest(
      dispatch,
      {
        page: 1,
        perPage: 100,
        query: '',
      },
      selectedCompany.company,
    )

    if (response?.data) {
      dispatch(setDepartments(response.data.data))
    }
  }

  const getProducts = async () => {
    if (!selectedCompany.company) return;

    const response = await findCompanyProductsRequest(
      dispatch,
      {
        page: 1,
        perPage: 100,
        query: '',
      },
      selectedCompany.company,
    )

    if (response?.data) {
      dispatch(setProducts(response.data.data))
    }
  }

  useEffect(() => {
    loadModules()
    getDepartments()
    getProducts()
  }, [])

  useEffect(() => {
    if (companyCommons?.departments?.length === 0) return

    const dpt = localStorage.getItem('department')
    if (!dpt) return

    selectDepartment(dpt)
  }, [companyCommons.departments]);

  const getSubdepartments = async (id: string) => {
    if (!selectedCompany.company) return

    const response = await findCompanySubdepartmentsRequest(
      dispatch,
      selectedCompany.company,
      id,
    )

    if (response.data) {
      setSubdepartments(response.data.data)
    }
  }

  const selectDepartment = (id: string) => {
    const d = companyCommons.departments.find((dt) => dt.id === id)
    if (!d) {
      setDepartment(undefined)
      return
    }

    getSubdepartments(d.id || '')

    setDepartment(d)
  }

  useEffect(() => {
    if (subdepartments.length === 0) return

    const subdpt = localStorage.getItem('subdepartment')
    if (!subdpt) return

    selectSubdepartment(subdpt)
  }, [subdepartments]);

  const selectSubdepartment = (id: string) => {
    const d = subdepartments.find((dt) => dt.id === id)
    if (!d) {
      setSubdepartment(undefined)
      return
    }

    setSubdepartment(d)
  }

  const selectProduct = (id: string) => {
    const d = companyCommons.products.find((dt) => dt.id === id)
    if (!d) {
      setProduct(undefined)
      return
    }

    setProduct(d)
  }

  const selectAmount = (value: string) => {

    value = value.replaceAll(",", ".")
    const n = value ? parseFloat(value) : 0
    setAmount(isNaN(n) ? '0' : value)
    return
  }

  const create = async (save: boolean) => {
    const alerts = new RCAlerts(module)
    if (!product) {
      alerts.alert(
        'error',
        'Debes seleccionar un producto',
      )
      return
    }

    if (!department) {
      alerts.alert(
        'error',
        `Debes seleccionar un ${transformToSingular(departmentModule?.settings.private.moduleName || '')}`,
      )
      return
    }

    if (!subdepartment) {
      alerts.alert(
        'error',
        `Debes seleccionar un ${transformToSingular(departmentModule?.settings.private.subdepartmentName || '')}`,
      )
      return
    }


    const a = parseFloat(amount || '0')
    if (isNaN(a) || a === 0) {
      alerts.alert(
        'error',
        'Debes introducir un valor',
        `Recuerda que el producto debe ser de ${product?.presentationValue} ${product?.presentationMeasurementUnit}. Intentá no pasarte de esa medida.`
      )
      return
    }

    alerts.preCreate({
      async onAccept() {
        if (!selectedCompany.company || !product.id) return
        const response = await createCompanyProductStockRequest(
          dispatch,
          {
            amount: a,
            departmentId: department.id || '',
            subDepartmentId: subdepartment.id || '',
            description,
          },
          selectedCompany.company,
          product.id
        )

        alerts.created({
          response,
          successHttpCode: EHttpStatusCode.ACCEPTED,
          onAccept() {
            if (response.data.id) {
              dispatch(setScanner({
                showModal: true,
                title: product.name,
                subtitle: `${a} ${product.measurementUnit}`,
                sessionId: response.data.id
              }))
            }

            if (!save) {
              setAmount('')
              setDepartment(undefined)
              setSubdepartment(undefined)
              setDescription('')
              setProduct(undefined)
            }
          }
        })
      },
      onCancel() {
      }
    }, `Crearás una unidad de stock de ${product.name} por ${a} ${product.presentationMeasurementUnit}.\n
    Asignarás este producto a ${department.name} - ${subdepartment.name}.\n\nEsta acción no se puede deshacer.`,
  )

  }

  const setAmountFromWeightScale = (weightGr: number) => {
    if (product?.presentationMeasurementUnit === 'kg') {
      setAmount((weightGr / 1000).toFixed(3))
      return
    }

    setAmount(weightGr.toFixed(0))
    return
  }

  useEffect(() => {
    if (department?.id) {
      localStorage.setItem('department', department.id)
    }

    if (subdepartment?.id) {
      localStorage.setItem('subdepartment', subdepartment.id)
    }

  }, [department, subdepartment]);

  return <SecureView module={module} setModule={setModule}>
    <Card className={'col-6 offset-3'}>
      <CardBody title={"Crear unidad de stock"}>
        <Row>
          <Col sm={6}>
            <label>{CapitalizeAndTransform(departmentModule?.settings.private.moduleName || '')}</label>
            <select className={'custom-select'} value={department?.id}
                    onChange={(e) => selectDepartment(e.target.value)}
            >
              <option>Selecciona...</option>
              {companyCommons.departments.map((depto) => {
                return <option key={depto.id} value={depto.id}>{depto.name}</option>
              })
              }
            </select>
          </Col>
          <Col sm={6}>
            <label>{CapitalizeAndTransform(departmentModule?.settings.private.subdepartmentName || '')}</label>
            <select disabled={!department} className={'custom-select'} value={subdepartment?.id}
                    onChange={(e) => selectSubdepartment(e.target.value)}>
              <option>Selecciona...</option>
              {subdepartments.map((st) => {
                return <option key={st.id} value={st.id}>{st.name}</option>
              })
              }
            </select>
          </Col>

          <Col sm={12} className={'mt-4'}>
            <Autocomplete
              disablePortal
              options={companyCommons.products.map((pt) => {
                return {
                  id: pt.id,
                  label: `[${pt.sku}] ${pt.name} (${pt.presentationValue} ${pt.presentationMeasurementUnit})`,
                }
              })}
              onChange={(e, value) => selectProduct(value?.id || '')}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField {...params} label="Producto" />}
            />
          </Col>

          <Form.Group className="col-6 mt-3">
            <Form.Label>Cantidad</Form.Label>
            <InputGroup className="">
              <Form.Control
                type="text"
                placeholder="100"
                value={amount}
                onChange={(e) => selectAmount(e.target.value)}
              />
              <InputGroup.Text>{product?.presentationMeasurementUnit}</InputGroup.Text>
            </InputGroup>
          </Form.Group>

          <Form.Group className="mt-3 col-6">
            <Form.Label>Descripción</Form.Label>
            <Form.Control
              type="text"
              placeholder="Descripción"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
            />
          </Form.Group>
        </Row>

        <WeightScale
          onChangeWeight={setAmountFromWeightScale}
        />

        <Button variant="success" type="button" onClick={() => create(false)} className={'mt-5'}>
          <i className='ti-save'></i> Crear
        </Button>

        <Button variant="info" type="button" onClick={() => create(true)} className={'mt-5 ml-2'}>
          <i className='ti-save'></i> Crear y guardar selección
        </Button>

        <Button variant="secondary" type="button" onClick={goBack} className={'mt-5 ml-2'}>
          Cancelar
        </Button>
      </CardBody>
    </Card>
  </SecureView>
}
